import Sortable from "@stimulus-components/sortable"
import { patch } from "@rails/request.js"

class Phase {
  constructor(letter) {
    this.letter = letter
    this.index = letter.charCodeAt(0) - 65
  }

  next() {
    if (this.letter === "Z") {
      return new Phase("Z")
    }

    return new Phase(String.fromCharCode(this.letter.charCodeAt(0) + 1))
  }

  previous() {
    if (this.letter === "A") {
      return new Phase("A")
    }

    return new Phase(String.fromCharCode(this.letter.charCodeAt(0) - 1))
  }
}

export default class Controller extends Sortable {
  static targets = ["phase", "ingredient"]

  connect() {
    this.phaseMovedOver = null
    super.connect()
  }

  onMove(evt, _originalEvent) {
    if (!this.phaseTargets.includes(evt.related)) return
    if (evt.related.dataset.phase === "A") return false

    this.phaseMovedOver = evt.related
  }

  onStart(evt) {
    this.phaseMovedOver = null
    this.currentIndex = evt.oldIndex
  }

  async onUpdate({ item, newIndex }) {
    const data = this._formData(item, newIndex)
    const newPhase = data.get(this._attributeName("phase"))

    const response = await patch(item.dataset.sortableUpdateUrl, {
      body: data,
      responseKind: this.responseKindValue,
    })

    if (response.ok) { item.dataset.phase = newPhase }
  }

  _attributeName(name) {
    return this.resourceNameValue ? `${this.resourceNameValue}[${name}]` : name
  }

  _formData(item, newIndex) {
    const data = new FormData()
    let phase = new Phase(item.dataset.phase)

    // If we moved over a phase then we need to send that with
    // the form update. We should usually move to the next phase unless our new
    // index is higher than before (meaning we moved up)
    if (this.phaseMovedOver) {
      const targetPhase = new Phase(this.phaseMovedOver.dataset.phase)

      if (this.currentIndex < newIndex) {
        phase = targetPhase
      } else {
        phase = targetPhase.previous()
      }
    }

    // We need to adjust the position in two ways:
    // 1. We need to adjust the index to be 1-based instead of 0-based
    // 2. We need to ignore the position of the phase we are moving over
    let adjustedPosition = (newIndex + 1) - (phase.index + 1)
    if (adjustedPosition < 1) { adjustedPosition = phase.index + 1 }

    data.append(this._attributeName("phase"), phase.letter)
    data.append(this._attributeName("position"), adjustedPosition)

    return data
  }


  get defaultOptions() {
    return {
      onStart: this.onStart.bind(this),
      onMove: this.onMove.bind(this),
      filter: ".sort-filtered",
    }
  }
}
