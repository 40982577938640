import { Controller as BaseController } from "@hotwired/stimulus"
import { Editor } from '@tiptap/core'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'

export class Controller extends BaseController {
  static targets = [ "input" ]

  connect() {
    this.newEditor()
    document.addEventListener("turbo:render", this.newEditor.bind(this))
  }

  newEditor() {
    let formInput = this.inputTarget
    if(formInput.hidden) return

    this.editor = new Editor({
      element: this.element,
      extensions: [
        StarterKit,
        Underline
      ],
      content: formInput.value,
      onUpdate() {
        formInput.innerHTML = this.getHTML();
      },
      editorProps: {
        attributes: {
          class: "textarea w-full !max-w-none prose prose-sm sm:prose lg:prose-lg xl:prose-xl",
          "aria-label": "Rich text editor",
          rows: 5
        }
      }
    })

    formInput.hidden = true
  }

  toggleBold() {
    this.editor.chain().focus().toggleBold().run();
  }

  toggleItalics() {
    this.editor.chain().focus().toggleItalic().run();
  }

  toggleUnderline() {
    this.editor.chain().focus().toggleUnderline().run();
  }

  toggleHeadingOne() {
    this.editor.chain().focus().toggleHeading({ level: 1 }).run();
  }

  toggleHeadingTwo() {
    this.editor.chain().focus().toggleHeading({ level: 2 }).run();
  }

  toggleHeadingThree() {
    this.editor.chain().focus().toggleHeading({ level: 3 }).run();
  }

  toggleBulletList() {
    this.editor.chain().focus().toggleBulletList().run();
  }

  toggleOrderedList() {
    this.editor.chain().focus().toggleOrderedList().run();
  }

  toggleHorizontalRule() {
    this.editor.chain().focus().setHorizontalRule().run();
  }

  toggleBlockQuote() {
    this.editor.chain().focus().toggleBlockquote().run();
  }
}
